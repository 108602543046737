import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template", "list"];

  connect(){
  }

  addItem(event){
    event.preventDefault();
    
    const content = this.templateTarget.content.cloneNode(true);
    const index   = (event && event.params && event.params.index) ? event.params.index : new Date().getTime();
    
    content.querySelectorAll('input').forEach( node => {
      const name = node.getAttribute('name');
      node.setAttribute('name', name.replace("{{index}}", index));
    });

    const wrap = content.querySelector('.removable');
    const btn  = content.querySelector('button.remove-item');
    
    wrap.setAttribute('id', wrap.getAttribute('id').replace("{{index}}", index));
    btn.setAttribute('data-target', btn.getAttribute('data-target').replace("{{index}}", index));

    this.listTarget.appendChild(content);
  }

  removeItem(event){
    const btn = event.target;
    const sel = btn.getAttribute('data-target');
    const node = this.listTarget.querySelector(sel);
    
    if( node ){
      if( node.hasAttribute('data-persisted') ){
        if( node.dataset.persisted.toString() === 'true' ){
          const del = node.querySelector('input[type=hidden][rel=remove]')
          if( del ) del.value = 1;
          node.classList.add('sr-only');
          
        }else{
          node.remove();
        }
      }else{ 
        node.remove();
      }
    }
  }
}
