import "./controllers"
import "@hotwired/turbo-rails"
import "bootstrap/js/dist/tab"
import "bootstrap/js/dist/alert"
import "bootstrap/js/dist/carousel"
import "bootstrap/js/dist/collapse"


function loadShareThis(){
  window.switchTo5x = true;
  
  const script = document.createElement('script');
  const prior  = document.getElementsByTagName('script')[0];

  script.onload = (_)=>{
    window.stLight.options({publisher: "bb1cf668-967c-4b59-a5f0-6d2ae38c6d73", doNotHash: false, doNotCopy: false, hashAddressBar: false});
  }

  script.src = "https://w.sharethis.com/button/buttons.js";
  prior.parentNode.insertBefore(script, prior);
}

document.addEventListener('turbo:load', ()=>{
  if(document.querySelectorAll('.stButton').length > 0 ) return;

  if( stlib && stlib.cookie ) stlib.cookie.deleteAllSTCookie();
  document.querySelector('script[src*="sharethis.com"]').remove();
  document.querySelectorAll('.stButton').forEach( node => {
    node.remove();
  });

  loadShareThis();
});