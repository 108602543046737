import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="nav"
export default class extends Controller {
  static targets = ['menu'];

  connect() {
  }

  showLibrary(event){
    const lib = document.getElementById('media_library');
    lib.classList.add('-open');
  }

  toggle(){
    this.menuTarget.classList.toggle('-active')
  }
}
